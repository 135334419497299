import logo from "./logo.svg";
import "./App.css";
import Card from "./Components/Card";

function App() {
  return (
    <div>
      <Card
        image="https://media.licdn.com/dms/image/D4E03AQET58Ake52XNQ/profile-displayphoto-shrink_800_800/0/1687876147600?e=2147483647&v=beta&t=rhP31M0DL5EHJVpHK3uN3ndjeOddG8y_0xx0-gomvls"
        name="Omkar Khilari"
        position="WEB DEV TEAM"
        linkedin="linkedin"
        mail="mail"
      />
    </div>
  );
}

export default App;
